:root {
  --bg-50: #2d3545;
  --bg-100: #2a3140;
  --bg-200: #272d3b;
  --bg-300: #232936;
  --bg-400: #202530;
  --bg-500: #1d212b;
  --bg-600: #191d26;
  --bg-700: #161921;
  --bg-800: #13151c;
  --bg-900: #111318;
  --bg-950: #0d0e12;
  --bg-1000: #000000;
  --bg-popup: rgba(0, 0, 0, 0.6);

  --border-50: #ffffff;
  --border-100: #c9cfd8;
  --border-200: #9ea3b6;
  --border-300: #888ba3;
  --border-400: #73768c;
  --border-500: #65687b;
  --border-600: #58596a;
  --border-700: #58596a;
  --border-800: #252a37;
  --border-900: #1f242f;
  --border-950: #161b26;

  --color-text-50: #ffffff;
  --color-text-100: #f5f5f6;
  --color-text-200: #cecfd2;
  --color-text-300: #8f95a8;
  --color-text-400: #62697c;
  --color-text-500: #494e5b;
  --color-text-600: #3e424e;
  --color-text-700: #25262c;
  --color-text-800: #1c1d21;
  --color-text-900: #17181c;
  --color-text-950: #121316;

  --color-primary-10: #f2f1ff;
  --color-primary-100: #e7e6ff;
  --color-primary-200: #d2d1ff;
  --color-primary-300: #b0abff;
  --color-primary-400: #8a7cff;
  --color-primary-500: #6448ff;
  --color-primary-600: #4818f1;
  --color-primary-700: #370dca;
  --color-primary-800: #2f0da5;
  --color-primary-900: #190570;
  --color-primary-950: #343b51;

  --color-green-50: #bbf7d1;
  --color-green-100: #70c2a1;
  --color-green-200: #2ed190;
  --color-green-300: #21ba5c;
  --color-green-400: #17a24c;
  --color-green-500: #167f3f;
  --color-green-600: #166535;
  --color-green-700: #14532e;
  --color-green-800: #052e17;
  --color-green-950: #031c0e;

  --color-red-50: #ffc5c5;
  --color-red-100: #f68484;
  --color-red-200: #f25454;
  --color-red-300: #ee1c1c;
  --color-red-400: #c80d0d;
  --color-red-500: #ab0d0d;
  --color-red-600: #881414;
  --color-red-700: #6b1010;
  --color-red-800: #4b0404;
  --color-red-900: #300a03;

  --color-purple-50: #ebe9fe;
  --color-purple-100: #beb5fd;
  --color-purple-200: #855ce6;
  --color-purple-300: #5d25d9;
  --color-purple-400: #4b1eae;
  --color-purple-500: #401a94;
  --color-purple-600: #311471;
  --color-purple-700: #250e62;
  --color-purple-800: #1f0c50;
  --color-purple-900: #140835;

  --color-orange-50: #ffd7a5;
  --color-orange-100: #ffa252;
  --color-orange-200: #ff710a;
  --color-orange-300: #f05200;
  --color-orange-400: #cc3d02;
  --color-orange-500: #ac330c;
  --color-orange-600: #95300e;
  --color-orange-700: #70240a;
  --color-orange-800: #541b08;
  --color-orange-900: #461204;

  --color-pink-50: #ffadba;
  --color-pink-100: #ff6c86;
  --color-pink-200: #fa3960;
  --color-pink-300: #e31649;
  --color-pink-400: #c40c3e;
  --color-pink-500: #a40d3b;
  --color-pink-600: #8c0f39;
  --color-pink-700: #780d31;
  --color-pink-800: #620421;
  --color-pink-900: #4e031a;
}

$bg-50: #2d3545;
$bg-100: #2a3140;
$bg-200: #272d3b;
$bg-300: #232936;
$bg-400: #202530;
$bg-500: #1d212b;
$bg-600: #191d26;
$bg-700: #161921;
$bg-800: #13151c;
$bg-900: #111318;
$bg-960: #0d0e12;
$bg-1000: #000000;
$bg-popup: rgba(0, 0, 0, 0.6);

$border-50: #ffffff;
$border-100: #c9cfd8;
$border-200: #9ea3b6;
$border-300: #888ba3;
$border-400: #73768c;
$border-500: #65687b;
$border-600: #58596a;
$border-700: #58596a;
$border-800: #252a37;
$border-900: #1f242f;
$border-950: #161b26;

$color-text-50: #ffffff;
$color-text-100: #f5f5f6;
$color-text-200: #cecfd2;
$color-text-300: #8f95a8;
$color-text-400: #62697c;
$color-text-500: #494e5b;
$color-text-600: #3e424e;
$color-text-700: #25262c;
$color-text-800: #1c1d21;
$color-text-900: #17181c;
$color-text-950: #121316;

$color-primary-10: #f2f1ff;
$color-primary-100: #e7e6ff;
$color-primary-200: #d2d1ff;
$color-primary-300: #b0abff;
$color-primary-400: #8a7cff;
$color-primary-500: #6448ff;
$color-primary-600: #4818f1;
$color-primary-700: #370dca;
$color-primary-800: #2f0da5;
$color-primary-900: #190570;
$color-primary-950: #343b51;

$color-green-50: #bbf7d1;
$color-green-100: #70c2a1;
$color-green-200: #2ed190;
$color-green-300: #21ba5c;
$color-green-400: #17a24c;
$color-green-500: #167f3f;
$color-green-600: #166535;
$color-green-700: #14532e;
$color-green-800: #052e17;
$color-green-950: #031c0e;

$color-red-50: #ffc5c5;
$color-red-100: #f68484;
$color-red-200: #f25454;
$color-red-300: #ee1c1c;
$color-red-400: #c80d0d;
$color-red-500: #ab0d0d;
$color-red-600: #881414;
$color-red-700: #6b1010;
$color-red-800: #4b0404;
$color-red-900: #300a03;

$color-purple-50: #ebe9fe;
$color-purple-100: #beb5fd;
$color-purple-200: #855ce6;
$color-purple-300: #5d25d9;
$color-purple-400: #4b1eae;
$color-purple-500: #401a94;
$color-purple-600: #311471;
$color-purple-700: #250e62;
$color-purple-800: #1f0c50;
$color-purple-900: #140835;

$color-orange-50: #ffd7a5;
$color-orange-100: #ffa252;
$color-orange-200: #ff710a;
$color-orange-300: #f05200;
$color-orange-400: #cc3d02;
$color-orange-500: #ac330c;
$color-orange-600: #95300e;
$color-orange-700: #70240a;
$color-orange-800: #541b08;
$color-orange-900: #461204;

$color-pink-50: #ffadba;
$color-pink-100: #ff6c86;
$color-pink-200: #fa3960;
$color-pink-300: #e31649;
$color-pink-400: #c40c3e;
$color-pink-500: #a40d3b;
$color-pink-600: #8c0f39;
$color-pink-700: #780d31;
$color-pink-800: #620421;
$color-pink-900: #4e031a;

// TODO: generate using scss classes

/* Background Colors */
.bg-50 {
  background-color: var(--bg-50);
}
.bg-100 {
  background-color: var(--bg-100);
}
.bg-200 {
  background-color: var(--bg-200);
}
.bg-300 {
  background-color: var(--bg-300);
}
.bg-400 {
  background-color: var(--bg-400);
}
.bg-500 {
  background-color: var(--bg-500);
}
.bg-600 {
  background-color: var(--bg-600);
}
.bg-700 {
  background-color: var(--bg-700);
}
.bg-800 {
  background-color: var(--bg-800);
}
.bg-900 {
  background-color: var(--bg-900);
}
.bg-960 {
  background-color: var(--bg-960);
}
.bg-1000 {
  background-color: var(--bg-1000);
}
.bg-popup {
  background-color: var(--bg-popup);
}

/* Border Colors */
.border-50 {
  border-color: var(--border-50);
}
.border-100 {
  border-color: var(--border-100);
}
.border-200 {
  border-color: var(--border-200);
}
.border-300 {
  border-color: var(--border-300);
}
.border-400 {
  border-color: var(--border-400);
}
.border-500 {
  border-color: var(--border-500);
}
.border-600 {
  border-color: var(--border-600);
}
.border-700 {
  border-color: var(--border-700);
}
.border-800 {
  border-color: var(--border-800);
}
.border-900 {
  border-color: var(--border-900);
}
.border-950 {
  border-color: var(--border-950);
}

/* Text Colors */
.color-text-50 {
  color: var(--color-text-50);
}
.color-text-100 {
  color: var(--color-text-100);
}
.color-text-200 {
  color: var(--color-text-200);
}
.color-text-300 {
  color: var(--color-text-300);
}
.color-text-400 {
  color: var(--color-text-400);
}
.color-text-500 {
  color: var(--color-text-500);
}
.color-text-600 {
  color: var(--color-text-600);
}
.color-text-700 {
  color: var(--color-text-700);
}
.color-text-800 {
  color: var(--color-text-800);
}
.color-text-900 {
  color: var(--color-text-900);
}
.color-text-950 {
  color: var(--color-text-950);
}

/* Primary Colors */
.color-primary-10 {
  color: var(--color-primary-10);
}
.color-primary-100 {
  color: var(--color-primary-100);
}
.color-primary-200 {
  color: var(--color-primary-200);
}
.color-primary-300 {
  color: var(--color-primary-300);
}
.color-primary-400 {
  color: var(--color-primary-400);
}
.color-primary-500 {
  color: var(--color-primary-500);
}
.color-primary-600 {
  color: var(--color-primary-600);
}
.color-primary-700 {
  color: var(--color-primary-700);
}
.color-primary-800 {
  color: var(--color-primary-800);
}
.color-primary-900 {
  color: var(--color-primary-900);
}
.color-primary-950 {
  color: var(--color-primary-950);
}

/* Green Colors */
.color-green-50 {
  color: var(--color-green-50);
}
.color-green-100 {
  color: var(--color-green-100);
}
.color-green-200 {
  color: var(--color-green-200);
}
.color-green-300 {
  color: var(--color-green-300);
}
.color-green-400 {
  color: var(--color-green-400);
}
.color-green-500 {
  color: var(--color-green-500);
}
.color-green-600 {
  color: var(--color-green-600);
}
.color-green-700 {
  color: var(--color-green-700);
}
.color-green-800 {
  color: var(--color-green-800);
}
.color-green-950 {
  color: var(--color-green-950);
}

/* Red Colors */
.color-red-50 {
  color: var(--color-red-50);
}
.color-red-100 {
  color: var(--color-red-100);
}
.color-red-200 {
  color: var(--color-red-200);
}
.color-red-300 {
  color: var(--color-red-300);
}
.color-red-400 {
  color: var(--color-red-400);
}
.color-red-500 {
  color: var(--color-red-500);
}
.color-red-600 {
  color: var(--color-red-600);
}
.color-red-700 {
  color: var(--color-red-700);
}
.color-red-800 {
  color: var(--color-red-800);
}
.color-red-900 {
  color: var(--color-red-900);
}

/* Purple Colors */
.color-purple-50 {
  color: var(--color-purple-50);
}
.color-purple-100 {
  color: var(--color-purple-100);
}
.color-purple-200 {
  color: var(--color-purple-200);
}
.color-purple-300 {
  color: var(--color-purple-300);
}
.color-purple-400 {
  color: var(--color-purple-400);
}
.color-purple-500 {
  color: var(--color-purple-500);
}
.color-purple-600 {
  color: var(--color-purple-600);
}
.color-purple-700 {
  color: var(--color-purple-700);
}
.color-purple-800 {
  color: var(--color-purple-800);
}
.color-purple-900 {
  color: var(--color-purple-900);
}

/* Orange Colors */
.color-orange-50 {
  color: var(--color-orange-50);
}
.color-orange-100 {
  color: var(--color-orange-100);
}
.color-orange-200 {
  color: var(--color-orange-200);
}
.color-orange-300 {
  color: var(--color-orange-300);
}
.color-orange-400 {
  color: var(--color-orange-400);
}
.color-orange-500 {
  color: var(--color-orange-500);
}
.color-orange-600 {
  color: var(--color-orange-600);
}
.color-orange-700 {
  color: var(--color-orange-700);
}
.color-orange-800 {
  color: var(--color-orange-800);
}
.color-orange-900 {
  color: var(--color-orange-900);
}

/* Pink Colors */
.color-pink-50 {
  color: var(--color-pink-50);
}
.color-pink-100 {
  color: var(--color-pink-100);
}
.color-pink-200 {
  color: var(--color-pink-200);
}
.color-pink-300 {
  color: var(--color-pink-300);
}
.color-pink-400 {
  color: var(--color-pink-400);
}
.color-pink-500 {
  color: var(--color-pink-500);
}
.color-pink-600 {
  color: var(--color-pink-600);
}
.color-pink-700 {
  color: var(--color-pink-700);
}
.color-pink-800 {
  color: var(--color-pink-800);
}
.color-pink-900 {
  color: var(--color-pink-900);
}

/* Background Colors for Specific Colors */
.bg-red-50 {
  background-color: var(--color-red-50);
}
.bg-red-100 {
  background-color: var(--color-red-100);
}
.bg-red-200 {
  background-color: var(--color-red-200);
}
.bg-red-300 {
  background-color: var(--color-red-300);
}
.bg-red-400 {
  background-color: var(--color-red-400);
}
.bg-red-500 {
  background-color: var(--color-red-500);
}
.bg-red-600 {
  background-color: var(--color-red-600);
}
.bg-red-700 {
  background-color: var(--color-red-700);
}
.bg-red-800 {
  background-color: var(--color-red-800);
}
.bg-red-900 {
  background-color: var(--color-red-900);
}

.bg-green-50 {
  background-color: var(--color-green-50);
}
.bg-green-100 {
  background-color: var(--color-green-100);
}
.bg-green-200 {
  background-color: var(--color-green-200);
}
.bg-green-300 {
  background-color: var(--color-green-300);
}
.bg-green-400 {
  background-color: var(--color-green-400);
}
.bg-green-500 {
  background-color: var(--color-green-500);
}
.bg-green-600 {
  background-color: var(--color-green-600);
}
.bg-green-700 {
  background-color: var(--color-green-700);
}
.bg-green-800 {
  background-color: var(--color-green-800);
}
.bg-green-950 {
  background-color: var(--color-green-950);
}

.bg-purple-50 {
  background-color: var(--color-purple-50);
}
.bg-purple-100 {
  background-color: var(--color-purple-100);
}
.bg-purple-200 {
  background-color: var(--color-purple-200);
}
.bg-purple-300 {
  background-color: var(--color-purple-300);
}
.bg-purple-400 {
  background-color: var(--color-purple-400);
}
.bg-purple-500 {
  background-color: var(--color-purple-500);
}
.bg-purple-600 {
  background-color: var(--color-purple-600);
}
.bg-purple-700 {
  background-color: var(--color-purple-700);
}
.bg-purple-800 {
  background-color: var(--color-purple-800);
}
.bg-purple-900 {
  background-color: var(--color-purple-900);
}

.bg-orange-50 {
  background-color: var(--color-orange-50);
}
.bg-orange-100 {
  background-color: var(--color-orange-100);
}
.bg-orange-200 {
  background-color: var(--color-orange-200);
}
.bg-orange-300 {
  background-color: var(--color-orange-300);
}
.bg-orange-400 {
  background-color: var(--color-orange-400);
}
.bg-orange-500 {
  background-color: var(--color-orange-500);
}
.bg-orange-600 {
  background-color: var(--color-orange-600);
}
.bg-orange-700 {
  background-color: var(--color-orange-700);
}
.bg-orange-800 {
  background-color: var(--color-orange-800);
}
.bg-orange-900 {
  background-color: var(--color-orange-900);
}

.bg-pink-50 {
  background-color: var(--color-pink-50);
}
.bg-pink-100 {
  background-color: var(--color-pink-100);
}
.bg-pink-200 {
  background-color: var(--color-pink-200);
}
.bg-pink-300 {
  background-color: var(--color-pink-300);
}
.bg-pink-400 {
  background-color: var(--color-pink-400);
}
.bg-pink-500 {
  background-color: var(--color-pink-500);
}
.bg-pink-600 {
  background-color: var(--color-pink-600);
}
.bg-pink-700 {
  background-color: var(--color-pink-700);
}
.bg-pink-800 {
  background-color: var(--color-pink-800);
}
.bg-pink-900 {
  background-color: var(--color-pink-900);
}

.bg-50 {
  background-color: var(--bg-50);
}
.bg-100 {
  background-color: var(--bg-100);
}
.bg-200 {
  background-color: var(--bg-200);
}
.bg-300 {
  background-color: var(--bg-300);
}
.bg-400 {
  background-color: var(--bg-400);
}
.bg-500 {
  background-color: var(--bg-500);
}
.bg-600 {
  background-color: var(--bg-600);
}
.bg-700 {
  background-color: var(--bg-700);
}
.bg-800 {
  background-color: var(--bg-800);
}
.bg-900 {
  background-color: var(--bg-900);
}
.bg-950 {
  background-color: var(--bg-950);
}
.bg-1000 {
  background-color: var(--bg-1000);
}
