// Text Editor
.editor {
  border: 1px solid #424253;
  border-radius: 6px;
  transition: all 0.05s;

  &:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(129, 140, 248, 0.2);
    border: 1px solid #5749ef;
  }

  &:hover {
    border: 1px solid #5749ef;
  }

  &-invalid {
    border: 1px solid #ee1c1c;

    &:focus-within {
      box-shadow: 0 0 0 0.2rem rgba(238, 28, 28, 0.2);
      border: 1px solid #ee1c1c;
    }

    &:hover {
      border: 1px solid #ee1c1c;
    }
  }

  .NgxEditor__MenuBar {
    background-color: transparent;
    border-bottom: 1px solid #424253;
  }

  .NgxEditor {
    background-color: transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    color: #fff;
    max-height: 20rem;
    min-height: 10rem;
    overflow-y: auto;
  }

  .NgxEditor__MenuItem .NgxEditor__MenuItem--Icon {
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: #5749ef;
    }
  }

  .NgxEditor__Dropdown .NgxEditor__Dropdown--Selected,
  .NgxEditor__Dropdown .NgxEditor__Dropdown--Open {
    background: transparent;
    color: #5749ef;
    cursor: pointer;

    &:hover {
      background: transparent;
    }
  }

  .NgxEditor__MenuItem.NgxEditor__MenuItem--Active,
  .NgxEditor__MenuItem .NgxEditor__MenuItem--Active {
    background-color: transparent;
  }

  .NgxEditor__Seperator {
    border-color: #424253;
  }

  .NgxEditor__Dropdown .NgxEditor__Dropdown--DropdownMenu {
    background-color: #424253;
  }

  .NgxEditor__Dropdown:hover {
    cursor: pointer;
    background-color: transparent;
  }

  .NgxEditor__Dropdown .NgxEditor__Dropdown--Item {
    cursor: pointer;
    background-color: #424253;

    &:hover {
      background-color: darken($color: #424253, $amount: 10);
    }
  }

  .NgxEditor__Dropdown .NgxEditor__Dropdown--Active {
    background-color: darken($color: #424253, $amount: 10);
  }
}

ngx-editor {
  --ngx-editor-placeholder-color: #6c6c838a;
}
