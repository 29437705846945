$primaryColor: #4818f1 !default;
$primaryLightColor: #6448ff !default;
$primaryLighterColor: #8a7cff !default;
$primaryLightestColor: #b0abff !default;
$primaryTextColor: #ffffff !default;

$highlightBg: $primaryLighterColor !default;
$highlightTextColor: $primaryTextColor !default;
$highlightFocusBg: #370dca !default;

$mainBlack: #0b0b0d;
$darkPurple: #0a0118;

@import '../_variables';
@import './_fonts';
@import '../../theme-base/_components';
@import '../_extensions';
@import './colors';
