// Sizes
.w-6rem {
  width: 6rem;
}

.w-8rem {
  width: 8rem;
}

.w-22rem {
  width: 22rem;
}

.w-36px {
  width: 36px;
}

.w-400px {
  width: 400px;
}

.w-600px {
  width: 600px;
}

.w-90vw {
  width: 90vw;
}

.w-fit {
  width: fit-content;
}

.max-w-260px {
  max-width: 260px;
}

.max-w-356px {
  max-width: 356px;
}

.max-w-400px {
  max-width: 400px;
}

.max-w-800px {
  max-width: 800px;
}

.max-w-1280px {
  max-width: 1280px;
}

.min-w-300px {
  min-width: 300px;
}

.h-6rem {
  height: 6rem;
}

.h-8rem {
  height: 8rem;
}

.h-22rem {
  height: 22rem;
}

.h-36px {
  height: 36px;
}

.w-90vh {
  width: 70vh;
}

.h-110p {
  height: 110%;
}

.min-h-76vh {
  min-height: 76vh;
}

// Margin

.mt-20p {
  margin-top: 20%;
}

.mt-9 {
  margin-top: 9rem;
}

.-mx-15px {
  margin-left: -15px;
  margin-right: -15px;
}

.-mb-20px {
  margin-bottom: -20px;
}

// Padding
.px-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.pr-16px {
  padding-right: 16px;
}

.pl-24px {
  padding-left: 24px;
}

.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.px-24px {
  padding-left: 24px;
  padding-right: 24px;
}

// Flex-Grid

.justify-self-center {
  justify-self: center;
}

// Position

.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.right-5 {
  right: 5%;
}

.-top-10 {
  top: -10%;
}

// Colors
.bg-gray-700 {
  background-color: var(--gray-700);
}

.bg-gray-750 {
  background-color: var(--gray-750);
}

.bg-gray-950 {
  background-color: var(--gray-950);
}

.bg-gray-1000 {
  background-color: var(--gray-1000);
}

.bg-transparent {
  background-color: transparent;
}

.text-gray-200 {
  color: var(--gray-200);
}

// Filters
.filter-white img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(10000%);
}

.filter-white svg {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(10000%);
}

// Effects

.hover-scale-05:hover {
  transform: scale(1.05);
}

.-translate-y-48px {
  transform: translateY(-48px);
}

// Border
.border-gray-750 {
  border-color: var(--gray-750);
}

.border-gray-800 {
  border-color: var(--gray-800);
}
