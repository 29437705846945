@import './ngx-editor/editor.scss';
@import '../prime/themes/custom/indigo/colors';

// Style Reset
* {
  box-sizing: border-box;
}

h1,
body {
  background-color: #0b0b0d;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: none;
  text-decoration: none;
}

@mixin custom-scroll {
  overflow-y: scroll;
  -ms-overflow-style: scrollbar;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #5749ef;
    box-shadow: 0 0 1px transparent;
    cursor: pointer;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.custom-scroll,
.p-dialog-content {
  @include custom-scroll();
}

.custom-underline {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    height: 1px;
    width: 100%;
    background-color: currentColor;
    transform: scale(0);
    transition: transform 0.3s;
    transform-origin: left;
  }

  &:hover::after {
    transform: scale(1);
  }
}

.print-only {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }

  .print-only {
    display: block;
  }
}
