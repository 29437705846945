@import './indigo/colors';

@mixin focused-ring($ring-color) {
  box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px $ring-color,
    0 1px 2px 0 rgba(0, 0, 0, 0);
}

@layer primeng {
  .p-button-label {
    font-weight: 400;
  }

  .p-selectbutton > .p-button,
  .p-togglebutton.p-button {
    transition: background-color $transitionDuration,
      border-color $transitionDuration, box-shadow $transitionDuration;
  }

  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        transition: background-color $transitionDuration,
          border-color $transitionDuration, box-shadow $transitionDuration;
      }
    }
  }

  .p-tabview {
    .p-tabview-nav {
      li {
        .p-tabview-nav-link {
          transition: background-color $transitionDuration,
            border-color $transitionDuration, box-shadow $transitionDuration;
        }
      }
    }
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 6px 6px !important;
    margin-bottom: 1rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 0;
  }

  .p-tabview .p-tabview-nav {
    border: none;
    display: flex;
    justify-content: space-around;

    li {
      padding: 0;
      flex-grow: 1;

      a {
        padding: 0;
      }

      & a {
        padding: 0;

        & div {
          width: 100%;
        }
      }
    }
  }

  .p-tabview-nav-link {
    padding: 0;

    & div div {
      background-color: #202530;
      height: 4px;
    }

    &:hover div div {
      background-color: #2d3545;
    }
  }

  .p-tabview-nav {
    & .p-highlight a div div {
      background-color: #fff;
      height: 4px;
    }
  }

  // TODO: refactor
  .vertical-tabview {
    & .p-tabview-nav {
      display: flex;
      flex-direction: column;
      border: none;

      & li {
        & a {
          border: none;
        }

        &.p-highlight span {
          color: #4d43b7;
        }
      }
    }

    & .p-tabview {
      display: flex;
      gap: 92px;
    }

    & .p-tabview-panels {
      flex: 1;
    }
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border: none;
    padding: 1rem;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 1rem;
    border: none;
  }

  .p-tabmenu {
    .p-tabmenu-nav {
      .p-tabmenuitem {
        .p-menuitem-link {
          transition: background-color $transitionDuration,
            border-color $transitionDuration, box-shadow $transitionDuration;
        }
      }
    }
  }

  .p-carousel {
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background-color: $primaryColor;
    }
  }

  .p-galleria {
    .p-galleria-indicators .p-galleria-indicator.p-highlight button {
      background-color: $primaryColor;
    }
  }

  .p-button {
    &:focus {
      @include focused-ring(rgba($buttonBg, 0.7));
    }

    &.p-button-secondary:enabled:focus {
      @include focused-ring(rgba($secondaryButtonBg, 0.7));
    }

    &.p-button-success:enabled:focus {
      @include focused-ring(rgba($successButtonBg, 0.7));
    }

    &.p-button-info:enabled:focus {
      @include focused-ring(rgba($infoButtonBg, 0.7));
    }

    &.p-button-warning:enabled:focus {
      @include focused-ring(rgba($warningButtonBg, 0.7));
    }

    &.p-button-help:enabled:focus {
      @include focused-ring(rgba($helpButtonBg, 0.7));
    }

    &.p-button-danger:enabled:focus {
      @include focused-ring(rgba($dangerButtonBg, 0.7));
    }
  }

  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &.p-datatable-dragpoint-top > td {
          box-shadow: inset 0 2px 0 0 $primaryColor;
        }

        &.p-datatable-dragpoint-bottom > td {
          box-shadow: inset 0 -2px 0 0 $primaryColor;
        }
      }
    }
  }

  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &.p-datatable-dragpoint-top > td {
          box-shadow: inset 0 2px 0 0 $primaryColor;
        }

        &.p-datatable-dragpoint-bottom > td {
          box-shadow: inset 0 -2px 0 0 $primaryColor;
        }
      }
    }
  }

  .p-speeddial-item {
    &.p-focus > .p-speeddial-action {
      @include focused-ring(rgba($buttonBg, 0.7));
    }
  }

  .p-toast-message {
    backdrop-filter: blur(10px);
  }

  .p-message {
    .p-message-close {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  @media (max-width: 599.98px) {
    .p-toast {
      bottom: 4rem !important;
    }
  }

  .p-toast {
    max-width: 90% !important;
    right: 0.8rem !important;

    .p-toast-message {
      .p-toast-icon-close {
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  .p-inline-message-text {
    font-weight: 500;
  }

  .p-picklist-buttons .p-button,
  .p-orderlist-controls .p-button {
    transition: opacity $transitionDuration,
      background-color $transitionDuration, color $transitionDuration,
      border-color $transitionDuration, box-shadow $transitionDuration;
  }

  .p-steps {
    .p-steps-item {
      &.p-highlight {
        .p-steps-number {
          background: $primaryColor;
          color: $primaryTextColor;
        }
      }
    }
  }

  .p-tag {
    padding: 0.3rem 0.4rem;
    border: 1px solid var(--gray-700);
    text-transform: capitalize;
  }

  p-tag.Draft .p-tag {
    color: $color-text-400;
  }
  p-tag.Review .p-tag {
    color: $color-orange-100;
  }
  p-tag.Rejected .p-tag {
    color: $color-red-300;
  }
  p-tag.Ready.for.Publish .p-tag {
    color: $color-primary-500;
  }
  p-tag.Published .p-tag {
    color: $color-green-300;
  }
  p-tag.Changes.Requested .p-tag {
    color: $color-pink-200;
  }
  p-tag.Publish.Requested .p-tag {
    color: $color-green-100;
  }
  p-tag.Unpublish.Requested .p-tag {
    color: $color-primary-300;
  }
  p-tag.Publishing .p-tag {
    color: $color-green-200;
  }

  p-tag.Pending.Review .p-tag {
    color: var(--cyan-400);
  }

  .p-listbox {
    background: transparent;

    &:hover {
      border-color: $primaryColor;
    }

    & .p-listbox-list {
      padding: 6px;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      border: 1px solid var(--gray-700);
      border-radius: 6px;
      &-wrapper {
        max-height: fit-content !important;
      }

      & .p-listbox-item {
        background-color: var(--gray-800);
        color: #fff;
        font-size: 1rem;
        text-transform: capitalize;
        border-radius: 4px;
        transition: all 0.2s;
        padding: 0.5rem;

        &.p-highlight {
          background-color: $primaryColor;
        }
      }
    }
  }

  .p-listbox.ng-invalid.ng-touched {
    border: 1px solid $errorColor;
  }
}

// TODO: find appropriate places for these

.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: $primaryColor;
}

.p-component.ng-invalid.ng-dirty:focus-visible,
.p-element.ng-invalid.ng-dirty.ng-touched .p-password-input:focus-visible {
  box-shadow: 0 0 0 0.2rem rgba(238, 28, 28, 0.2);
}

.p-listbox-item.p-focus {
  background-color: var(--gray-800);

  &.p-highlight {
    background-color: $primaryColor;
  }
}

.p-inputtext {
  transition: none;
  caret-color: $primaryTextColor;

  &:-webkit-autofill {
    -webkit-text-fill-color: $primaryTextColor;
  }

  &.autofill-main-black {
    -webkit-autofill {
      -webkit-box-shadow: 0 0 0px 40rem #0b0b0d inset;
      box-shadow: 0 0 0px 40rem #0b0b0d inset;
    }
  }

  &.autofill-gray-950 {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 40rem #1b1b22 inset;
      box-shadow: 0 0 0px 40rem #1b1b22 inset;
    }
  }
}

.p-dynamic-dialog {
  margin: 0 1rem;
}
